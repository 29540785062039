import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';

import { Theme } from '@admin/atoms/Theme';
import { Placeholder } from '@admin/molecules/RichEditor/Editor/atoms';

import styles from './RichTextWrapperPlugin.module.scss';

export const RichTextWrapperPlugin = () => (
    <Theme>
        <div className={styles.RichTextWrapperPlugin}>
            <RichTextPlugin
                contentEditable={<ContentEditable className={styles.input}></ContentEditable>}
                placeholder={<Placeholder />}
                ErrorBoundary={LexicalErrorBoundary}
            />
        </div>
    </Theme>
);
