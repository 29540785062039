import { ReactNode } from 'react';

import { useContextData } from '@common/useContextData';

interface Props {
    children: ReactNode;
}

export const Theme = ({ children }: Props) => {
    const { platform } = useContextData();

    return <div data-theme={platform.id}>{children}</div>;
};
